


























































































































































































































































































































































































































































.main_introduce_yourself_wrapper {
  .second_with_rtl {
    .el-checkbox {
      flex-direction: row-reverse;
    }
  }
}
// .when_checked_label{

// }
